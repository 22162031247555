import React from 'react'
import { IProject } from 'model/project'
import { Theme, Tooltip, Typography, makeStyles } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import { useTextInEllipsis } from 'hooks/useTextInEllipsis'
import { ArchiveUnarchiveProjectButton } from 'component/sharingGroup/common/projectArchivedStateBadge/archiveUnarchiveProjectButton'
import classNames from 'classnames'
import { ARCHIVED_PROJECT_OPACITY } from 'features/project/utils'

const useProjectCardStyles = makeStyles<Theme, { isArchived: boolean; isDisabled: boolean }>(theme => ({
  projectCardRoot: {
    alignItems: 'center',
    borderRadius: 12,
    cursor: ({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer'),
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    paddingBlock: 8,
    width: '100%',
  },
  informationContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: 8,
    minWidth: 0,
    '&:hover': {
      color: ({ isDisabled }) => !isDisabled && theme.palette.primary.main,
      cursor: ({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer'),
    },
  },
  icon: {
    color: theme.threadPalette.lightSoftGray,
    marginRight: 8,
  },
  projectName: {
    color: 'inherit',
    fontSize: 18,
    fontWeight: 400,
    opacity: ({ isArchived }) => (isArchived ? ARCHIVED_PROJECT_OPACITY : 1),
  },
  threadsCount: {
    fontSize: 12,
    fontWeight: 400,
    opacity: ({ isArchived }) => (isArchived ? ARCHIVED_PROJECT_OPACITY : 1),
    whiteSpace: 'nowrap',
  },
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 30,
    justifyContent: 'center',
  },
}))

interface IProjectCardProps {
  project: IProject
  onClick?(): void
  prefixComponent?: JSX.Element
  suffixComponent?: JSX.Element
  className?: string
  showActionsButton?: boolean
  isDisabled?: boolean
}
export const ProjectCard = ({
  project,
  onClick,
  prefixComponent,
  suffixComponent,
  className,
  showActionsButton = true,
  isDisabled = false,
}: IProjectCardProps) => {
  const classes = useProjectCardStyles({ isArchived: project.isArchived, isDisabled })
  const { textRef: projectNameRef, hasEllipsis: showTooltip } = useTextInEllipsis()

  const showThreadsCountLabel = project.threadCount !== undefined
  const threadsLabelPluralized = project.threadCount === 1 ? 'thread' : 'threads'
  const threadsCountLabel = `${project.threadCount} ${threadsLabelPluralized}`

  return (
    <div className={classNames(classes.projectCardRoot, className)}>
      {prefixComponent}
      <div className={classes.informationContainer} onClick={onClick}>
        <FolderIcon className={classes.icon} />
        <Tooltip title={showTooltip ? project.name : ''} arrow={true} aria-label="project name">
          <Typography ref={projectNameRef} className={classes.projectName} noWrap>
            {project.name}
          </Typography>
        </Tooltip>
        {showThreadsCountLabel && <Typography className={classes.threadsCount}>({threadsCountLabel})</Typography>}
      </div>
      {showActionsButton && (
        <div className={classes.actionsContainer}>
          <div>
            <ArchiveUnarchiveProjectButton project={project} />
          </div>
          {suffixComponent}
        </div>
      )}
    </div>
  )
}
