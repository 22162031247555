import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Popover, Typography, Theme, InputBase, List, useTheme, PopoverOrigin } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import Spacer from 'component/layoutUtils/spacer'
import ProjectItem from './projectItem'
import { IBaseProject } from 'model/project'
import debounce from 'lodash/debounce'
import AppLoading from 'component/appLoading'
import NewProjectItem from './newProjectItem'
import { useProjectsList } from 'hooks/useProjects/useProjectsList'
import { useDisconnectThreadFromProjectUseCase } from 'usecases/disconnectThreadFromProject'
import { useCreateNewProjectUseCase } from 'usecases/createProject'
import { useThreadProjects } from 'hooks/useThreadProjects/useThreadProjects'
import { useConnectThreadToProjectUseCase } from 'usecases/connectThreadToProject'

const useConnectThreadToProjectModalStyles = makeStyles((theme: Theme) => ({
  connectThreadToProjectModalRoot: {
    backgroundColor: theme.threadPalette.darkerBlue,
    borderRadius: 10,
    color: theme.threadPalette.white,
    paddingBlock: 8,
    width: 345,
  },
  headerContainer: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 10,
    paddingInline: 8,
  },
  headerIcon: {
    fontSize: 26,
  },
  headerTitle: {
    fontSize: 18,
  },
  contentContainer: {
    paddingInline: 12,
  },
  searchProjectInput: {
    backgroundColor: theme.threadPalette.white,
    borderRadius: 5,
    marginBottom: 14,
    paddingInline: 6,
  },
  selectProjectSubtitle: {
    color: theme.palette.error.main,
    fontSize: 12,
    fontWeight: 'bold',
  },
}))

interface IConnectThreadToProjectModalProps {
  thread: IThread
  isOpen: boolean
  onDialogClose: () => void
  anchorEl: HTMLElement | (() => HTMLElement) | null
  anchorOrigin?: PopoverOrigin
  prefetchProjects?: boolean
}

export const ConnectThreadToProjectModal = ({
  thread,
  isOpen,
  onDialogClose,
  anchorEl,
  anchorOrigin,
  prefetchProjects = false,
}: IConnectThreadToProjectModalProps) => {
  const classes = useConnectThreadToProjectModalStyles()
  const theme = useTheme()

  const isProjectsListFetchingEnabled = React.useMemo(() => {
    return isOpen || prefetchProjects
  }, [isOpen, prefetchProjects])

  const {
    projectsList,
    changeSearchTerm,
    isInitialLoading: isSearchingProjects,
  } = useProjectsList({
    pageSize: 10,
    isFetchingEnabled: isProjectsListFetchingEnabled,
  })
  const { createNewProjectUseCase } = useCreateNewProjectUseCase()

  const { disconnectThreadFromProjectUseCase } = useDisconnectThreadFromProjectUseCase()
  const { projects } = useThreadProjects({ threadId: thread.id, isFetchingEnabled: isProjectsListFetchingEnabled })

  const [projectName, setProjectName] = React.useState<string>('')

  const onClose = React.useCallback(() => {
    setProjectName('')
    onDialogClose()
  }, [onDialogClose])

  const { connectThreadToProjectUseCase } = useConnectThreadToProjectUseCase({ onSuccess: onClose })

  const debouncedCheckExistingProjectByTitle = React.useCallback(debounce(changeSearchTerm, 300), [])
  // Load projects on opening modal and reloading list when closing and emptying input box
  React.useEffect(() => {
    debouncedCheckExistingProjectByTitle(projectName)
  }, [projectName])

  const onChangeProjectName = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const projectName = event.target.value
    setProjectName(projectName)
  }

  const isProjectConnected = React.useCallback(
    (project: IBaseProject): boolean => {
      if (projects.length === 0) return false

      return projects.some(connectedProject => connectedProject.id === project.id)
    },
    [projects]
  )

  const onSelectProject = async (project: IBaseProject) => {
    if (isProjectConnected(project)) return

    connectThreadToProjectUseCase({ threadId: thread.id, projectId: project.id })
  }

  const onDisconnectProject = async (project: IBaseProject) => {
    if (!isProjectConnected(project)) return
    disconnectThreadFromProjectUseCase({ projectId: project.id, threadId: thread.id })
  }

  const isThereALoadedProjectWithSameInputName = projectsList.some(project => project.name === projectName.trim())
  const shouldShowCreateProject = projectName && !isThereALoadedProjectWithSameInputName

  const [isCreatingProject, setIsCreatingProject] = React.useState<boolean>(false)

  const onCreateNewProject = React.useCallback(async () => {
    setIsCreatingProject(true)

    const newProject = await createNewProjectUseCase({ projectName })

    if (newProject) {
      onSelectProject(newProject)
    }
    setIsCreatingProject(false)
  }, [setIsCreatingProject, createNewProjectUseCase, onSelectProject])

  return (
    <Popover
      classes={{ paper: classes.connectThreadToProjectModalRoot }}
      open={isOpen}
      elevation={8}
      anchorEl={anchorEl}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'top',
          horizontal: 'left',
        }
      }
      onClose={onClose}
    >
      <div className={classes.headerContainer}>
        <LinkIcon className={classes.headerIcon} />
        <Spacer width={8} />
        <Typography align="left" className={classes.headerTitle}>
          Connect a project
        </Typography>
      </div>
      <div className={classes.contentContainer}>
        <InputBase
          value={projectName}
          onChange={onChangeProjectName}
          className={classes.searchProjectInput}
          placeholder="Search for a project..."
          fullWidth
        />
        <Typography className={classes.selectProjectSubtitle}>Select a project or create a new one</Typography>
      </div>

      {isSearchingProjects && (
        <>
          <Spacer height={10} />
          <AppLoading visible small fill={theme.threadPalette.lightBlue} padding={4} legWidth={6} legHeight={12} />
        </>
      )}
      <List disablePadding>
        {projectsList.map(project => (
          <ProjectItem
            key={project.id}
            name={project.name}
            isConnected={isProjectConnected(project)}
            onSelect={() => onSelectProject(project)}
            onUnlink={() => onDisconnectProject(project)}
          />
        ))}
        {shouldShowCreateProject && (
          <NewProjectItem name={projectName} isLoading={isCreatingProject} onSelect={onCreateNewProject} />
        )}
      </List>
      <Spacer height={10} />
    </Popover>
  )
}
