import React from 'react'
import { Theme, Typography, makeStyles } from '@material-ui/core'
import { IProject } from 'model/project'

import { useProjectsTreeContext } from './projectsTreeContext'
import { useProjectsList } from 'hooks/useProjects/useProjectsList'
import BeatProgress from 'component/beatProgress'
import { ProjectNode } from './projectNode'
import classNames from 'classnames'
import { NewProjectCard } from './newProjectCard'

const useProjectNodeStyles = makeStyles((theme: Theme) => ({
  nestedProjectsListRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: 'inherit',
    marginLeft: 20,
  },
  noNestedProjectsLabel: {
    color: theme.threadPalette.brownishGray,
    fontSize: 16,
    fontWeight: 400,
  },
  nestedProjectsLoadingRoot: {
    maxWidth: 720,
  },
}))

const NestedProjectsList = ({
  project,
  showActionsButton,
  isMovingCurrentProject,
  noNestedProjectsClassName,
  isCreatingNewProject = false,
  onProjectCreationFinished,
}: {
  project?: IProject
  showActionsButton?: boolean
  isMovingCurrentProject?: boolean
  noNestedProjectsClassName?: string
  isCreatingNewProject?: boolean
  onProjectCreationFinished?(): void
}) => {
  const classes = useProjectNodeStyles()
  const { isFetchingEnabled, onlyActive, onlyArchived } = useProjectsTreeContext()

  const { projectsList: nestedProjects, isInitialLoading: isLoadingNestedProjects } = useProjectsList({
    pageSize: 50,
    parentProject: project,
    isFetchingEnabled: isFetchingEnabled && !!project?.id,
    onlyActive,
    onlyArchived,
  })

  const isLoading = isLoadingNestedProjects && nestedProjects.length === 0

  const noProjectsToDisplay = nestedProjects.length === 0 && !isCreatingNewProject
  const hasNoNestedProjects = !isLoadingNestedProjects && noProjectsToDisplay

  const loadingComponent = (
    <div className={classes.nestedProjectsLoadingRoot}>
      <BeatProgress />
    </div>
  )

  if (!project?.id) return loadingComponent
  return (
    <>
      {isLoading && loadingComponent}
      {hasNoNestedProjects && (
        <Typography className={classNames(classes.noNestedProjectsLabel, noNestedProjectsClassName)}>
          No nested projects
        </Typography>
      )}
      {isCreatingNewProject && <NewProjectCard parentProject={project} onCloseCreation={onProjectCreationFinished} />}
      {nestedProjects.map(nestedProject => (
        <ProjectNode
          key={nestedProject.id}
          project={nestedProject}
          isMovingParentProject={isMovingCurrentProject}
          showActionsButton={showActionsButton}
        />
      ))}
    </>
  )
}

export default NestedProjectsList
