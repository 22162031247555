import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Popover, PopoverProps, Theme, Typography, TypographyProps } from '@material-ui/core'
import { useFullScreen } from 'hooks/useFullScreen'
import classnames from 'classnames'
import classNames from 'classnames'

const useDialogAnchoredOnComponentStyles = makeStyles((theme: Theme) => ({
  dialogRoot: {
    backgroundColor: theme.threadPalette.errorDialog,
    border: `solid 1px ${theme.threadPalette.lightishGray}`,
    borderRadius: 14,
    padding: '10px 10px 13px 13px',
    width: 360,
  },
  title: {
    color: theme.threadPalette.brownishGray,
    fontSize: 17,
    fontWeight: 'bold',
  },

  message: {
    color: theme.threadPalette.brownishGray,
    fontSize: 17,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  actionsContainerRightAligned: {
    justifyContent: 'flex-end',
    gap: 16,
  },
  actionLabel: {
    cursor: 'pointer',
    color: theme.threadPalette.brownishGray,
    fontSize: 20,
    fontWeight: 500,
    paddingBlock: 4,
    paddingInline: 24,
    borderRadius: 6,
  },
}))

export interface IDialogAction {
  label: string
  labelColor?: string
  paddingInline?: number
  backgroundColor?: string
  action?(): void
}

export type IDialogAnchoredOnComponentProps = {
  title?: string
  message?: string | JSX.Element
  textAlign?: TypographyProps['align']
  open?: boolean
  anchoredElementRef?: PopoverProps['anchorEl']
  actions?: IDialogAction[]
  rootClassName?: string
  actionsAlign?: 'center' | 'right'
} & Omit<PopoverProps, 'open' | 'anchorEl'>

const DialogAnchoredOnComponent: React.FC<IDialogAnchoredOnComponentProps> = ({
  children,
  title = '',
  message = '',
  anchoredElementRef,
  open = Boolean(anchoredElementRef),
  actions,
  textAlign = 'left',
  rootClassName,
  actionsAlign,
  ...popoverProps
}) => {
  const classes = useDialogAnchoredOnComponentStyles()
  const hasValidContent = !!message || !!title
  const isOpenDialog = hasValidContent && open
  const { isFullScreen: isMobileSizeScreen } = useFullScreen('md')
  return (
    <>
      <Popover
        classes={{ paper: classnames(classes.dialogRoot, rootClassName) }}
        open={isOpenDialog}
        elevation={0}
        anchorEl={anchoredElementRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobileSizeScreen ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        {...popoverProps}
      >
        {!!title && (
          <Typography className={classes.title} align={textAlign}>
            {title}
          </Typography>
        )}
        {!!message && (
          <Typography className={classes.message} align={textAlign}>
            {message}
          </Typography>
        )}
        {actions?.length && (
          <div
            className={classNames(classes.actionsContainer, {
              [classes.actionsContainerRightAligned]: actionsAlign === 'right',
            })}
          >
            {actions?.map((actionButton, index) => (
              <Typography
                key={index}
                className={classes.actionLabel}
                style={{
                  color: actionButton.labelColor,
                  backgroundColor: actionButton.backgroundColor,
                  paddingInline: actionButton.paddingInline,
                }}
                component="span"
                onClick={actionButton.action}
              >
                {actionButton.label}
              </Typography>
            ))}
          </div>
        )}
      </Popover>
      {children}
    </>
  )
}

export default DialogAnchoredOnComponent
