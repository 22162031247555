import { showSnackbar } from 'actions/snackbar'
import { errorHandlingWrapper } from 'api/errors/errorHandlingWrapper'
import { createNewProject } from 'api/v2/project'
import { useProjectActions } from 'hooks/useProjects/useProjectActions'
import { IBaseProject } from 'model/project'
import { useDispatch } from 'react-redux'

export const useCreateNewProjectUseCase = () => {
  const dispatch = useDispatch()

  const { refreshProjectsList } = useProjectActions()

  const createNewProjectUseCase = async ({
    projectName,
    parentProjectId,
  }: { projectName?: string; parentProjectId?: string } = {}) => {
    const { data: newProject, error } = await errorHandlingWrapper<IBaseProject>(
      () => createNewProject({ projectName, parentProjectId }),
      {
        defaultErrorMessage: `Error trying to create project "${projectName}"`,
      }
    )
    if (error) {
      dispatch(showSnackbar({ message: error }))
      return
    }

    refreshProjectsList()

    return newProject
  }

  return {
    createNewProjectUseCase,
  }
}
