import React from 'react'
import { Theme, makeStyles } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import classNames from 'classnames'
import InlineEditingTextField from 'shared/inputs/inlineEditingTextField'
import { ARCHIVED_PROJECT_OPACITY } from 'features/project/utils'
import { useCollapsableIcon } from 'shared/hooks/useCollapsableIcon'
import { useCreateNewProjectUseCase } from 'usecases/createProject'
import { IProject } from 'model/project'

const useNewNewProjectCardStyles = makeStyles((theme: Theme) => ({
  newProjectCardRoot: {
    alignItems: 'center',
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    width: '100%',
  },
  informationContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    gap: 8,
    paddingRight: '30%',
    minWidth: 0,
  },
  projectIcon: {
    color: theme.threadPalette.onFocusGray,
    cursor: 'unset',
    opacity: ARCHIVED_PROJECT_OPACITY,
    '&:hover': {
      color: theme.threadPalette.onFocusGray,
    },
  },
  folderIcon: {
    color: theme.threadPalette.lightSoftGray,
    marginRight: 8,
  },
  actionsContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 30,
    justifyContent: 'center',
  },
}))

interface INewProjectCardProps {
  parentProject?: IProject
  onSuccess?(): void
  onCloseCreation?(): void
  onCancel?(): void
  className?: string
}
export const NewProjectCard = ({ parentProject, onCloseCreation, onCancel, className }: INewProjectCardProps) => {
  const classes = useNewNewProjectCardStyles()
  const { component: expandNestedProjectsButton } = useCollapsableIcon({
    className: classes.projectIcon,
    isDisabled: true,
  })

  const { createNewProjectUseCase } = useCreateNewProjectUseCase()

  return (
    <div className={classNames(classes.newProjectCardRoot, className)}>
      {expandNestedProjectsButton}
      <div className={classes.informationContainer}>
        <FolderIcon className={classes.folderIcon} />
        <InlineEditingTextField
          onConfirmEdit={async newProjectName => {
            await createNewProjectUseCase({ projectName: newProjectName, parentProjectId: parentProject?.id })
          }}
          onStopEditing={onCloseCreation}
          onCancelEdit={onCancel}
          disableEditing
          autoFocus
        />
      </div>
    </div>
  )
}
