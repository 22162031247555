import React from 'react'
import { Theme, Typography, makeStyles, InputBase } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder'
import AppLoading from 'component/appLoading'
import { ThreadBlock } from './threadBlock'
import { useUpdateProjectUseCase } from 'usecases/updateProject'
import { NotesInput } from './notesInput'
import { IProject } from 'model/project'
import debounce from 'lodash/debounce'
import { isProjectNameStillTheDefaultOne } from 'domain/project'
import classNames from 'classnames'
import { useNewThreadModalContext } from 'component/newThreadModal/newThreadModal'
import NewItemButton from 'component/newItemButton'
import NestedProjectsList from 'component/projectsTree/nestedProjectsList'
import { useCollapsableIcon } from 'shared/hooks/useCollapsableIcon'
import { InlineCheckbox } from 'shared/inputs/inlineCheckbox'
import { useProjectsFilters } from 'hooks/useProjects/useProjectsFilters'
import NewProjectButton from 'features/project/newProjectButton'

const useProjectDetailsPageContentStyles = makeStyles((theme: Theme) => ({
  projectDetailsPageContentRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: 32,
    gap: 16,
    maxWidth: '100%',
    minWidth: 0,
    overflowY: 'scroll',
    paddingBlock: 20,
    paddingInline: 16,
    [theme.breakpoints.up('lg')]: {
      fontSize: 50,
      paddingBlock: 40,
      paddingInline: 120,
    },
  },

  projectInfoSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  projectNameContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 12,
    [theme.breakpoints.up('lg')]: {
      gap: 28,
    },
  },
  startIconContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 10,
  },
  projectEmoji: {
    color: theme.threadPalette.lightSoftGray,
    fontSize: 'inherit',
  },
  expandNestedProjectsIcon: {
    fontSize: 30,
  },
  projectName: {
    color: theme.threadPalette.brownishGray,
    fontSize: 'inherit',
    fontWeight: 500,
    paddingBlock: 0,
  },
  projectDefaultName: {
    color: theme.threadPalette.lightGray,
  },
  nestedProjectsListContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    gap: 8,
    marginBottom: 8,
    paddingLeft: 20,
  },
  projectsActionsContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    gap: 60,
  },
  projectsFiltersContainer: {
    alignItems: 'center',
    color: theme.threadPalette.lightGray,
    display: 'inline-flex',
    fontSize: 14,
    fontWeight: 500,
    gap: 8,
  },
  noNestedProjectsLabel: {
    color: theme.threadPalette.lightSoftGray,
  },
  projectNotesInput: {
    color: theme.threadPalette.menuItem.current,
    fontSize: 15,
    lineHeight: '22px',
    minHeight: 120,
    '&::placeholder': {
      color: theme.threadPalette.lightGray,
      fontSize: 18,
    },
  },
  threadsHeader: {
    alignItems: 'center',
    color: theme.threadPalette.lightGray,
    display: 'flex',
    fontSize: 36,
    fontWeight: 500,
    gap: 16,
  },
  noThreadsPlaceholder: {
    color: theme.threadPalette.lightGray,
    fontSize: 18,
    opacity: 0.6,
  },
  threadsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
}))

interface ProjectDetailsPageContentProps {
  project?: IProject
  isLoadingProject: boolean
}

export const ProjectDetailsPageContent = ({ project, isLoadingProject }: ProjectDetailsPageContentProps) => {
  const classes = useProjectDetailsPageContentStyles()
  const [projectNotes, setProjectNotes] = React.useState('')
  const [projectName, setProjectName] = React.useState('')
  const [isCreatingNewProject, setIsCreatingNewProject] = React.useState(false)

  const { openModal: openNewThreadModal, setProject: setNewThreadProject } = useNewThreadModalContext()
  const { isExpanded: isNestedProjectsExpanded, component: expandNestedProjectsButton } = useCollapsableIcon({
    className: classes.expandNestedProjectsIcon,
    isInitialExpanded: true,
  })
  const { projectsFilters, toggleActiveFilter, toggleArchivedFilter } = useProjectsFilters()

  const onNewThreadClick = React.useCallback(() => {
    if (project) setNewThreadProject(project)
    openNewThreadModal({ shouldNavigateToThread: false })
  }, [setNewThreadProject, openNewThreadModal, project])

  const { updateProjectUseCase } = useUpdateProjectUseCase()
  const hasThreads = !!project?.threadCount

  const onUpdateProjectName = async (updatedName: string) => {
    if (!project || project.name === updatedName) return
    await updateProjectUseCase({ projectId: project.id, projectName: updatedName })
  }

  const updateProjectNotes = async (updatedNotes: string) => {
    if (!project) return
    await updateProjectUseCase({ projectId: project.id, projectNotes: updatedNotes })
  }
  const debouncedUpdateProjectNotes = React.useCallback(debounce(updateProjectNotes, 500), [])

  const onChangeProjectNotes = (updatedNotes: string) => {
    setProjectNotes(updatedNotes)
    debouncedUpdateProjectNotes(updatedNotes)
  }

  React.useEffect(() => {
    setProjectNotes(project?.notes ?? '')
    setProjectName(project?.name ?? '')
  }, [project?.id])

  const isDefaultProjectName = isProjectNameStillTheDefaultOne(projectName ?? '')

  return (
    <div className={classes.projectDetailsPageContentRoot}>
      <section className={classes.projectInfoSection}>
        <div className={classes.projectNameContainer}>
          <div className={classes.startIconContainer}>
            {expandNestedProjectsButton}
            <FolderIcon className={classes.projectEmoji} />
          </div>
          {isLoadingProject && (
            <div>
              <AppLoading visible small padding={16} />
            </div>
          )}
          {!isLoadingProject && (
            <InputBase
              className={classNames(classes.projectName, { [classes.projectDefaultName]: isDefaultProjectName })}
              placeholder="Untitled Project"
              value={projectName}
              onChange={event => setProjectName(event.target.value)}
              onBlur={() => onUpdateProjectName(projectName)}
              fullWidth
              multiline
            />
          )}
        </div>
        {isNestedProjectsExpanded && (
          <div className={classes.nestedProjectsListContainer}>
            <div className={classes.projectsActionsContainer}>
              <div className={classes.projectsFiltersContainer}>
                <InlineCheckbox label="Active" isChecked={projectsFilters.onlyActive} onClick={toggleActiveFilter} />
                <InlineCheckbox
                  label="Archived"
                  isChecked={projectsFilters.onlyArchived}
                  onClick={toggleArchivedFilter}
                />
              </div>
              <NewProjectButton onClick={() => setIsCreatingNewProject(true)} isDisabled={isCreatingNewProject} />
            </div>
            <NestedProjectsList
              project={project}
              isCreatingNewProject={isCreatingNewProject}
              onProjectCreationFinished={() => setIsCreatingNewProject(false)}
              isMovingCurrentProject={false}
              noNestedProjectsClassName={classes.noNestedProjectsLabel}
              showActionsButton
            />
          </div>
        )}
        <NotesInput isLoading={isLoadingProject} value={projectNotes} onChange={onChangeProjectNotes} />
      </section>
      <section>
        <Typography className={classes.threadsHeader}>
          Threads <NewItemButton label="New Thread" onClick={onNewThreadClick} fontSize={14} iconSize={16} />
        </Typography>

        {!hasThreads && <Typography className={classes.noThreadsPlaceholder}>No threads in the project</Typography>}
        <div className={classes.threadsListContainer}>
          {project?.threads?.map(thread => <ThreadBlock key={thread.id} thread={thread} />)}
        </div>
      </section>
    </div>
  )
}
