import * as React from 'react'
import Popover from '@material-ui/core/Popover'
import Picker, { IEmojiData } from 'emoji-picker-react'
import EmojiIcon from './emoji'

interface IEmojiPickerProps {
  onEmojiClick: (event: React.MouseEvent, data: IEmojiData) => void
  className?: string
  wrapperClassName?: string
}

const EmojiPicker = ({ onEmojiClick, className, wrapperClassName }: IEmojiPickerProps) => {
  const [showEmojiPopup, setShowEmojiPopup] = React.useState(false)
  const popoverAnchor = React.useRef<HTMLDivElement>(null)

  const editorElement = popoverAnchor.current?.parentElement?.parentElement

  const onEmojiButtonClick = (event: React.MouseEvent) => {
    event.preventDefault()
    setShowEmojiPopup(prev => !prev)
  }

  const onEmojiSelected = React.useCallback(
    (event: React.MouseEvent, data: IEmojiData) => {
      onEmojiClick(event, data)
      setShowEmojiPopup(false)
    },
    [onEmojiClick]
  )

  return (
    <div ref={popoverAnchor} className={wrapperClassName}>
      <Popover
        open={showEmojiPopup}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setShowEmojiPopup(false)}
        container={editorElement}
      >
        <Picker onEmojiClick={onEmojiSelected} />
      </Popover>
      <div onClick={onEmojiButtonClick}>
        <EmojiIcon className={className} />
      </div>
    </div>
  )
}

export default EmojiPicker
