import { apiClient } from '@jetkit/react'
import { PaginatedResponse } from 'api/pagedResponse'
import { AxiosResponse } from 'axios'
import { PagedResponseDataProcessor } from 'dataProcessors/pagedResponse'
import { IBaseProject, IProject } from 'model/project'

const PREFIX = '/v2/project'

export const getProjectsList = async ({
  page,
  pageSize,
  searchString,
  isArchived,
  parentProjectId,
}: {
  page: number
  pageSize: number
  searchString?: string
  isArchived?: boolean
  parentProjectId?: string | null
}): Promise<PaginatedResponse<IProject[]>> => {
  const params = {
    page,
    page_size: pageSize,
    search_string: searchString ?? '',
    is_archived: isArchived,
    parent_project_id: parentProjectId,
  }
  if (isArchived === undefined) {
    delete params.is_archived
  }
  // requesting all projects
  if (parentProjectId === undefined) {
    delete params.parent_project_id
  }
  // requesting root projects
  if (parentProjectId === null) {
    params.parent_project_id = ''
  }
  const result = await apiClient.get(`${PREFIX}`, {
    params,
  })
  return PagedResponseDataProcessor(result)
}

export const createNewProject = async ({
  projectName,
  parentProjectId,
}: {
  projectName?: string
  parentProjectId?: string
}): Promise<IBaseProject> => {
  const result = await apiClient.post(`${PREFIX}`, { name: projectName, parent_project_id: parentProjectId ?? null })
  return result.data
}

export const getProject = async (projectId: string): Promise<IProject> => {
  const result = await apiClient.get(`${PREFIX}/${projectId}`)
  return result.data
}

export const connectThreadToProject = async ({
  projectId,
  threadId,
}: {
  projectId: string
  threadId: string
}): Promise<IProject> => {
  const result = await apiClient.post(`${PREFIX}/${projectId}/add-thread`, { threadId: threadId })
  return result.data
}

export const disconnectThreadFromProject = async ({ projectId, threadId }: { projectId: string; threadId: string }) => {
  const result = await apiClient.post(`${PREFIX}/${projectId}/remove-thread`, { threadId: threadId })
  return result.data
}

type IEditProjectAPIProps = {
  name?: string
  notes?: string
  isArchived?: boolean
}

export const editProject = async ({
  projectId,
  projectName,
  projectNotes,
  isArchived,
}: {
  projectId: string
  projectName?: string
  projectNotes?: string
  isArchived?: boolean
}): Promise<void> => {
  const result = await apiClient.patch<Omit<IEditProjectAPIProps, 'projectId'>, AxiosResponse<void>>(
    `${PREFIX}/${projectId}`,
    { name: projectName, notes: projectNotes, isArchived }
  )
  return result.data
}

export const getProjectByName = async (projectName: string): Promise<IProject> => {
  const encodedProjectName = encodeURIComponent(projectName)
  const result = await apiClient.get(`${PREFIX}/name/${encodedProjectName}`)
  return result.data
}

export const updateParentProject = async ({
  projectId,
  parentProjectId,
}: {
  projectId: string
  parentProjectId?: string
}): Promise<void> =>
  await apiClient.patch(`${PREFIX}/${projectId}/parent`, { parent_project_id: parentProjectId ?? null })

export const deleteProject = async (projectId: string): Promise<void> =>
  await apiClient.delete(`${PREFIX}/${projectId}`)
